.row {
  display: grid;
  grid-template-columns: 20px 40px 50px;
  width: 210px;
  font-size: 12px;
  gap: 50px;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid var(--grey-200);
  border-bottom: 0;

  &--deleted {
    background-color: var(--soft-red);
  }

  &--active {
    background-color: var(--soft-green);
  }

  &:last-child {
    border-bottom: 1px solid var(--grey-200);
  }

  &--header {
    background-color: var(--grey-200);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.dashboard {
  &__title {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    align-items: center;
    margin-bottom: 50px;

    .circle-data {
      margin: auto;
    }

    .rect-data {
      margin: auto;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
  }
}
.circle-data {
  border: 1px solid var(--grey-200);
  padding: 15px;
  border-radius: 50%;
  flex-flow: column;
  width: 150px;
  height: 150px;
  text-align: center;

  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
  }

  &__value {
    font-size: 36px;
    margin-top: 22px;
  }
}

.rect-data {
  border: 1px solid var(--grey-200);
  border-radius: 5px;
  flex-flow: column;
  width: 180px;
  text-align: center;

  &__title {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;

    &--off {
      background-color: var(--grey-200);
    }

    &--on {
      background-color: var(--soft-green);
    }
  }
}
