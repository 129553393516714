.calendar {
  display: inline-flex;
  align-items: center;

  div {
    color: var(--text-black);
    font-weight: 600;
    margin-right: 7px;
  }

  input {
    cursor: pointer;
  }

  &-panel {
    [data-pc-section='groupcontainer'] {
      padding: 8px 8px 0 8px;
    }

    [data-pc-section='container'] {
      padding-bottom: 8px;
    }

    [data-pc-section='decadetitletext'] {
      font-size: 14px;
      font-weight: 600;
    }

    [data-pc-section='monthpicker'],
    [data-pc-section='yearpicker'] {
      display: flex;
      padding: 0 8px 8px;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;
      width: 268px;

      span {
        width: 122px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        line-height: 20px;

        &:hover {
          background-color: var(--grey-200);
          border-radius: 5px;
        }
      }
    }

    [data-pc-section='container'],
    [data-pc-section='header'] {
      width: 268px;
    }

    [data-pc-section='header'] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid var(--grey-200);
      margin-bottom: 8px;

      button {
        padding: 0;
        background-color: unset;
        color: var(--text-black);
        font-weight: 600;
        height: auto;
        border: 0;
        font-size: 14px;

        &::before {
          display: none;
        }

        &[data-pc-section='monthtitle'] {
          margin-right: 7px;
        }

        &[data-pc-section='nextbutton'],
        &[data-pc-section='previousbutton'] {
          height: 14px;
        }
      }
    }

    thead th span {
      font-size: 14px;
    }

    tbody td {
      padding: 4px;

      &[data-p-today='true'] span {
        background-color: var(--grey-100);
      }

      span {
        display: inline-block;
        width: 20px;
        padding: 4px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        transition: 0.3s ease;
        cursor: pointer;
        font-size: 13px;

        &[data-p-disabled='true'] {
          color: var(--grey-300);
          cursor: default;
        }

        &[aria-selected='true'] {
          background-color: var(--primary-100);
          color: var(--white);
        }

        &:hover:not([aria-selected='true']):not([data-p-disabled='true']) {
          background-color: var(--grey-200);
        }
      }
    }
  }
}
