.pricing-page {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }

  .price-box {
    background-color: var(--white);
    padding: 35px 30px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border: 1px solid var(--grey-200);

    &--promo {
      border: 1px solid var(--primary);
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 22px;
      display: block;
    }

    &__period-price {
      white-space: nowrap;
      margin-bottom: 27px;
    }

    &__price {
      font-size: 32px;
      font-weight: 600;
    }

    &__period {
      font-size: 16px;
      font-weight: 700;
      display: inline-block;
      position: relative;
      top: -12px;
      left: 7px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 22px;
    }

    ul {
      font-size: 14px;
      list-style: none;
      padding: 0;
      margin-top: 0;
      margin-bottom: 36px;
    }

    button {
      width: 100%;
    }
  }
}
