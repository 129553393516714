.tag {
  display: inline-block;
  border: 1px solid var(--grey-200);
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: var(--grey-200);

  &:hover {
    transition: all 0.3s ease;
    background-color: var(--grey-150);
  }

  &--second {
    background-color: var(--white);

    &:hover {
      background-color: var(--white);
    }
  }
}
