.forgot-page {
  padding-bottom: 80px;

  .form-box {
    width: 480px;
    margin: 0 auto;
    height: 296px;
    display: block;

    @media only screen and (max-width: 520px) {
      width: 100%;
    }
  }

  .logo-box {
    margin: 80px auto 40px;
    inset: 0;
  }
}
