.add-prompt-page {
  &__panel {
    padding: 0 30px 30px;
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 60px;
  }

  .form {
    button {
      margin-top: 15px;
      width: 140px;
    }
  }
}

.help-box {
  font-size: 14px;
  margin-bottom: 30px;

  &__title {
    display: block;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    margin-bottom: 15px;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: var(--grey-200);
      position: absolute;
      left: 0;
      bottom: -5px;
    }

    &::before {
      content: '';
      width: 30px;
      height: 3px;
      right: 0;
      bottom: -5px;
      background-color: var(--primary);
      position: absolute;
      z-index: 1;
    }
  }
}
