.custom-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;

  .action-btn {
    background-color: transparent;
    border: 0;

    &:disabled {
      cursor: default;
      color: var(--grey-200);
    }

    &:hover:disabled {
      color: var(--grey-200);
    }
  }

  &--projects {
    .custom-table__row {
      grid-template-columns: 1fr 180px 220px 100px;

      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 205px 100px;
      }
    }
  }

  &__row {
    display: grid;

    .custom-table__cell {
      border-bottom: 1px solid var(--grey-200);
      font-size: 14px;
      padding: 12px 20px;
      display: inline-flex;
      align-items: center;

      &--center {
        text-align: center;
        justify-content: center;
      }

      &--gap {
        gap: 10px;
      }

      &--bold {
        font-weight: 600;
      }
    }

    &--header {
      box-shadow: 0 2px 6px 1px var(--grey-200);

      .custom-table__cell {
        font-size: 11px;
        font-weight: 600;
        color: var(--grey-500);
        text-transform: uppercase;
        padding: 15px 20px;
      }
    }
  }
}
