.filter-box {
  border-radius: 5px;
  border: 1px solid var(--grey-200);
  background-color: var(--grey-100);
  display: inline-flex;
  align-items: center;
  transition: 0.3s ease;
  overflow: hidden;

  @media only screen and (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
  }

  &__item {
    font-size: 13px;
    padding: 6px 8px;
    color: var(--text-black);
    text-decoration: none;
    position: relative;
    box-sizing: border-box;

    &::after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--grey-200);
    }

    &--active,
    &:hover {
      background-color: var(--white);
      text-decoration: none;
      color: var(--text-black);
    }

    &:last-child::after {
      display: none;
    }
  }
}
