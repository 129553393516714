@import 'assets/scss/index';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: var(--text-black);
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey-100);

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .page {
    flex: 1;
  }

  a {
    color: var(--primary);
    transition: 0.3s ease;
    text-decoration: none;

    &:hover {
      color: var(--primary-200);
      text-decoration: underline;
    }
  }
}
