.billing-data-page {
  display: flex;
  flex-flow: column;
  gap: 30px;

  &__row {
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 60px;
  }
}