.page-content {
  &--faq {
    font-size: 14px;
  }

  .page-box__title-action {
    &:not(:first-child) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 95%;
        background-color: var(--grey-200);
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
