.title-box {
  display: flex;
  position: sticky;
  top: 61px;
  justify-content: space-between;
  align-items: center;
  gap: 54px;
  padding: 12px 40px;
  height: 35px;
  z-index: 3;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-200);

  @media only screen and (max-width: 640px) {
    flex-flow: column;
    align-items: center;
    height: auto;
    gap: 20px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    gap: 4px;
    line-height: 24px;
    flex: 1 0;
    font-size: 20px;
  }

  &__category {
    color: var(--text-black);
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }

  &__main-child {
    position: relative;
    height: 24px;

    button {
      position: absolute;
      background-color: transparent;
      border: 0;
      top: 3px;
      left: 2px;
    }
  }

  &__subcategory {
    color: var(--grey-500);
    font-weight: 400;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__arrow {
    font-size: 18px;
    line-height: 25px;
    color: var(--grey-500);
  }

  .filters {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 450px) {
      flex-flow: column;
      width: 100%;
    }

    @media only screen and (max-width: 450px) {
      .calendar {
        width: 100%;

        .calendar {
          width: 100%;
        }
      }
    }
  }
}
