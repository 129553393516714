.form-box {
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--grey-200);
  padding: 30px;
  box-sizing: border-box;

  @media only screen and (max-width: 520px) {
    border-radius: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 33px;
  }

  &__form {
    display: flex;
    flex-flow: column;
    gap: 27px;
    box-sizing: border-box;

    button {
      margin-top: 5px;
    }
  }

  &__actions {
    display: flex;
    gap: 25px;

    button {
      width: 100%;
    }
  }

  &__invalid {
    border: 1px solid var(--red-100);

    + span {
      font-size: 12px;
      color: var(--red-300);
      position: absolute;
      bottom: -17px;
      left: 0;
    }
  }

  &__row {
    position: relative;

    &--link {
      text-align: right;
    }

    &--info {
      font-size: 14px;
      text-align: center;
    }

    &--with-col {
      display: flex;
      gap: 20px;
    }

    input {
      box-sizing: border-box;
      width: 100%;
    }
  }

  &__col {
    width: 50%;
    position: relative;
  }

  &__label {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
