.table-message {
  display: flex;
  flex-flow: column;
  gap: 30px;
  padding: 20px 0;

  &__box {
    padding: 30px;
    border: 1px solid var(--grey-200);
    border-radius: 5px;
    font-size: 14px;

    &:nth-child(2n) {
      background-color: var(--grey-100);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-200);
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-weight: 600;
  }

  &__position {
    display: flex;
    gap: 7px;
  }

  &__date {
    display: flex;
    gap: 7px;
    color: var(--grey-500);
  }

  &__title {
    margin-right: 7px;
  }

  &__content {
    line-height: 20px;
  }
}
