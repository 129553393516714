.team-edit-page {
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-gap: 60px;

  &__content {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  &__data {
    display: flex;
    flex-flow: column;
    gap: 6px;
  }

  &__row {
    display: flex;
    gap: 7px;
    font-size: 14px;

    span:first-child {
      font-weight: 600;
      color: var(--text-black);
    }

    span {
      color: var(--grey-500);
    }
  }

  .btn--link {
    margin-top: 15px;
    font-weight: 600;
  }
}
