a.user,
.user {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: var(--primary-100);
  font-size: 22px;
  border: 1px solid var(--primary-100);
  cursor: pointer;
  transition: 0.3s ease;
  color: var(--white);
  text-decoration: none;
  position: relative;

  &:hover {
    color: var(--white);
    text-decoration: none;
    opacity: 0.8;
  }

  &--new {
    background-color: var(--white);
    color: var(--text-black);
    font-size: 14px;
    font-weight: 600;

    &:hover {
      color: var(--text-black);
    }
  }

  &:nth-child(2n):not(.user--new):not(.user--inactive) {
    background-color: var(--primary-200);
    border: 1px solid var(--primary-200);
  }

  &:nth-child(3n):not(.user--new):not(.user--inactive) {
    background-color: var(--primary-300);
    border: 1px solid var(--primary-300);
  }

  &--inactive {
    background-color: var(--grey-200);
    border: 1px solid var(--grey-200);
    color: var(--text-color);

    &:hover {
      color: var(--text-color);
    }
  }

  &__type {
    background-color: var(--green-100);
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 50%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}
