.preview-project-page {
  &__twin-box {
    display: flex;
    gap: 34px;

    .stats-box {
      width: 100%;
      height: 131px;
    }
  }

  .warning-box {
    margin-top: 0;
    margin-bottom: 34px;
  }

  .stats-box {
    width: 100%;

    &--table {
      height: 300px;
    }

    &--pie-chart .empty-state {
      margin-top: 66px;
    }
  }

  .stats-box {
    @media only screen and (max-width: 1300px) {
      &--chart {
        margin-bottom: 34px;
      }
    }

    .page-box {
      &__title-action {
        margin-bottom: 12px;
      }
      &__content--value {
        font-size: 22px;

        &.visibility {
          font-weight: 600;
          font-size: 20px;

          &--notVisible {
            color: #b0b0b0;
          }

          &--minimal {
            color: #a2a2a2;
          }

          &--emerging {
            color: #7c7c7c;
          }

          &--good {
            color: #8fe52f;
          }

          &--strong {
            color: #6ed922;
          }

          &--excellent {
            color: #48c42e;
          }

          &--topPerformer {
            color: #14ab1c;
          }
        }
      }
    }

    .table-wrapper {
      padding: 0 0 30px;
    }
  }

  .more-btns {
    display: flex;
    flex-flow: row-reverse;
    position: relative;
    justify-content: center;

    button {
      background-color: unset;
      color: var(--text-black);
      padding: 0;
      height: unset;
      border-radius: 0;
      border: 0;
      cursor: pointer;

      &::before {
        display: none;
      }

      &:hover {
        color: var(--primary);
      }
    }

    span {
      cursor: default;
      transition: 0.3s ease;

      &:hover {
        color: var(--primary);
      }
    }

    &:hover {
      span {
        color: var(--primary);
      }

      .action-panel {
        padding: 3px 10px;
        opacity: 1;

        &--1 {
          width: 45px;
        }

        &--2 {
          width: 85px;
        }

        &--3 {
          width: 130px;
        }
      }
    }

    .action-panel {
      padding: 0;
      margin: auto 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      position: absolute;
      width: 0;
      height: 36px;
      right: 40px;
      box-sizing: border-box;
      background-color: var(--white);
      transition: 0.3s ease;
      opacity: 0;

      li {
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}
