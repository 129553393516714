.chart {
  display: flex;
  flex-flow: column;

  &--sm {
    flex-flow: column-reverse;
  }

  &__box {
    margin-top: 50px;
    position: relative;
  }

  &__label {
    position: absolute;
    top: 35px;
    font-size: 10px;

    &--left {
      left: -12px;
    }

    &--right {
      right: -12px;
    }

    &--bottom-left {
      left: -12px;
      top: unset;
      bottom: 42px;

      &::after {
        content: '';
        background-color: var(--white);
        position: absolute;
        width: 35px;
        height: 15px;
        left: 6px;
        top: -15px;
      }
    }
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    font-size: 12px;
    line-height: 17px;
    margin: 5px 0 0;
  }

  &__legend-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    flex: none;
    margin-right: 7px;
    border-radius: 3px;
  }

  &__legend-item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: var(--grey-300);
    transition: all 0.3s ease;

    &--active {
      color: var(--text-black);
    }
  }

  &-tooltip {
    &__content {
      display: flex;
      max-width: 360px;
      gap: 10px 20px;
      flex-wrap: wrap;
      margin-top: 10px;

      &--pie {
        margin-top: 0;

        .chart-tooltip__row {
          width: 290px;
          grid-template-columns: 5px 220px 50px;
        }
      }
    }

    &__row {
      width: 360px;
      display: grid;
      grid-template-columns: 5px 230px 100px;
      grid-gap: 5px;
      justify-content: space-between;
      align-items: center;

      .chart-tooltip__text {
        overflow: hidden;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      strong {
        text-align: right;
      }
    }

    &__text {
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }
}
