.simple-page {
  display: flex;

  &__belt {
    min-height: 60px;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-200);
    justify-content: center;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1003;
    width: 100%;
    align-items: center;

    .logo {
      margin-left: 20px;
      display: block;

      .logo-box {
        width: 120px;
        height: 24px;
      }
    }
  }

  &__page {
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    overflow-y: scroll;
  }
}
