[role='tooltip'] {
  position: absolute;
  z-index: 1000;
  background-color: var(--white);
  color: var(--text-black);
  border: 1px solid var(--grey-200);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre-wrap;
  pointer-events: none;
}

.p-tooltip-active {
  pointer-events: auto;
}

.tooltip {
  &--top {
    margin-top: -7px;
  }

  &--bottom {
    margin-top: 7px;
  }
}

.question-mark {
  cursor: default;
  font-size: 16px;
  color: var(--grey-500);

  &--left {
    margin-left: 4px;
  }
}
