.table {
  .cell-warning {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      font-size: 18px;
      color: var(--orange-300);
      cursor: default;
    }
  }

  .action-panel .btn--disabled-tooltip {
    cursor: default;
  }
}
