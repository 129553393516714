.tabs {
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);

  a {
    color: var(--text-black);

    &.tabs__item--active,
    &:hover {
      color: var(--black);
    }
  }

  &__item {
    padding: 12px 0;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    color: var(--text-black);
    text-decoration: none;
    display: flex;
    align-items: center;

    .material-symbols-outlined {
      font-size: 18px;
      margin-right: 5px;
      transition: 0.3s ease all;
    }

    &::after {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition: 0.3s ease all;
    }

    &:hover {
      color: var(--text-black);
      text-decoration: none;

      .material-symbols-outlined {
        color: var(--primary);
      }

      &::after {
        background-color: var(--primary);
        width: 100%;
      }
    }

    &--active {
      color: var(--black);

      .material-symbols-outlined {
        color: var(--primary);
      }

      &::after {
        background-color: var(--primary);
        width: 100%;
      }
    }
  }
}
