input {
  background-color: var(--white);
  border: 1px solid var(--grey-400);
  border-radius: 5px;
  padding: 7px 5px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  color: var(--text-black);
  transition: 0.3s ease;

  &:hover,
  &:focus {
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--grey-100);

    &:hover {
      border-color: var(--grey-400);
    }
  }

  &.input-search {
    height: 35px;
    width: 120px;
    padding-left: 35px;
    font-size: 14px;

    &::placeholder {
      color: var(--grey-400);
      opacity: 1;
    }

    &:focus {
      width: 220px;
    }
  }
}

.input-search-box {
  position: relative;

  &::before {
    font-family: 'Material Symbols Outlined';
    content: '\e8b6';
    position: absolute;
    font-size: 22px;
    line-height: 35px;
    color: var(--grey-400);
    left: 8px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}
