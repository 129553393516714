.project-wizard {
  display: flex;
  background-color: var(--white);

  &__belt {
    min-height: 60px;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-200);
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    .logo .logo-box {
      width: 120px;
      height: 24px;
    }
  }

  &__page {
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    overflow-y: scroll;
    flex-flow: column;
    position: relative;

    &--second .project-wizard__page-box {
      gap: 25px;
    }
  }

  &__loader {
    display: flex;
    flex-flow: column;
    gap: 40px;
  }

  &__content {
    padding: 90px 60px;
    display: grid;
    grid-template-columns: 60vw auto;
    gap: 80px;

    @media only screen and (max-width: 640px) {
      padding: 60px 20px;
      display: flex;
      gap: 0;
    }
  }

  &__page-box {
    max-width: 800px;
    display: flex;
    flex-flow: column;
    gap: 52px;

    @media only screen and (max-width: 640px) {
      max-width: unset;
      width: 100%;
    }
  }

  &__form {
    display: flex;
    flex-flow: column;
    gap: 24px;

    h2 {
      margin: 0 0 25px;
    }

    input {
      max-width: 430px;
    }
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-flow: column;
    gap: 12px;

    li {
      display: flex;
      gap: 7px;
      align-items: center;
      font-size: 14px;

      label {
        flex: 1;
      }
    }
  }

  &__action {
    border-top: 1px solid var(--grey-200);
    padding-top: 35px;
    display: flex;
    justify-content: flex-start;
    gap: 25px;
  }

  .btn-link {
    line-height: 20px;
    display: inline-flex;
    align-items: center;

    span {
      margin-top: 2px;
      font-size: 16px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
