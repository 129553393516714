div[role='progressbar'] {
  width: 100%;
  background-color: var(--grey-200);
  border-radius: 8px;
  height: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;

  div {
    background-color: var(--primary);
    height: 100%;
    transition: 0.3s ease;
  }

  div[data-pc-section='label'] {
    display: none;
  }
}
