.preview-prompt-page {
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 34px;
  }

  &__panel {
    padding: 0 30px 30px;
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 40px;
  }

  .form button {
    margin-top: 15px;
    width: 140px;
  }
}

.filters {
  .dropdown {
    width: 100px;
  }
}
