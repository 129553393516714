.subscription-page {
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-gap: 60px;

  &__row {
    display: grid;
    line-height: 50px;
    grid-template-columns: 130px 1fr;
    font-size: 13px;
    border-bottom: 1px solid var(--grey-200);
    grid-gap: 25px;

    &:last-child {
      border-bottom: 0;
    }

    div:first-child {
      font-weight: 600;
      border-right: 1px solid var(--grey-200);
      padding-left: 10px;
    }

    &:nth-child(2n) {
      background-color: var(--grey-100);
    }
  }
}
