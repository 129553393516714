.signup-page {
  display: flex;
  height: 100%;
  gap: 40px;
  justify-content: space-around;
  box-sizing: border-box;

  .form-box {
    width: 480px;
    margin: 0 auto;
    height: 455px;
    display: block;

    @media only screen and (max-width: 520px) {
      width: 100%
    }

    &__row--link a {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .logo-box {
    margin: 80px auto 0;
    inset: 0;
  }

  &__left {
    padding: 70px 45px;
    height: 100%;
    width: calc(100% - 520px);
    box-sizing: border-box;
    background: linear-gradient(180deg, var(--primary-100) 50%, var(--primary-200) 100%);

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    padding-bottom: 80px;
    gap: 60px;
  }

  &__title {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 18px;
    font-weight: 600;
    color: var(--white);
    text-align: center;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-200);
    text-align: center;
  }
}
