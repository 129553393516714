div[role='checkbox'] {
  width: 43px;
  height: 22px;
  border: 1px solid var(--grey-300);
  border-radius: 20px;
  position: relative;
  display: inline-flex;

  &[data-p-disabled='true'] {
    opacity: 0.7;
    border: 1px solid var(--grey-200);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;

    + span {
      background-color: var(--grey-300);
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;

      + span {
        background-color: var(--grey-300);
        cursor: default;
      }
    }

    &:checked + span {
      background-color: var(--primary);
      left: 22px;
    }

    &:checked:disabled + span {
      cursor: default;
    }
  }

  span {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 3px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.3s ease;
  }
}
