.no-results {
  width: 300px;
  padding: 60px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &__icon {
    font-size: 96px;
    font-weight: 700;
    text-align: center;
    color: var(--grey-300);
  }

  &__title {
    margin-top: 14px;
    font-weight: 600;
    font-size: 22px;
    color: var(--grey-600);
  }

  &__desc {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    color: var(--grey-600);
  }
}
