.toast {
  position: fixed;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--grey-200);
  padding: 5px 5px;
  width: 250px;
  height: 40px;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
