.panel {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--white);
  border-right: 1px solid var(--grey-200);
  padding: 0 15px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logo-box {
      width: 120px;
      height: 24px;
      margin: 25px 0 35px 13px;
    }

    @media only screen and (max-width: 992px) {
      background-color: transparent;
    }
  }

  &__menu-panel {
    display: flex;
    flex-flow: column;
    height: calc(100% - 110px);
    justify-content: space-between;
  }

  &__end {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 80px;
  }

  &__upgrade {
    display: inline-flex;
    flex-flow: column;
    width: 180px;
    height: 180px;
    border-radius: 20px;
    background-color: var(--primary-300);
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    &::after,
    &::before {
      content: '';
      width: 100px;
      height: 100px;
      position: absolute;
      border-radius: 50%;
      border: 1px solid var(--grey-200);
    }

    &::after {
      top: -67px;
      right: -40px;
    }

    &::before {
      top: -80px;
      right: 20px;
    }

    h4 {
      margin-top: 30px;
      font-size: 14px;
      color: var(--white);
      font-weight: 600;
    }

    .btn {
      display: inline;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__footer {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--grey-500);
  }
}

.menu {
  display: flex;
  flex-flow: column;
  gap: 10px;

  &__submenu {
    display: flex;
    flex-flow: column;
    gap: 10px;
    height: 0;
    overflow: hidden auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: 0.3s ease all;
    max-height: 250px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item-text-container {
    width: 100%;
    padding: 0 13px 0 48px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    &::before {
      content: '\e574';
      font-family: 'Material Symbols Outlined';
      color: var(--grey-500);
      font-size: 21px;
      line-height: 36px;
      position: absolute;
      inset: 0;
      margin-left: 13px;
      font-weight: 300;
    }
  }

  &__separator {
    height: 1px;
    width: 100%;
    background-color: var(--grey-200);
    display: block;
  }

  &__item-container {
    position: relative;

    button {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 2;

      &:hover + .menu__item {
        color: var(--white);
        text-decoration: none;
        background-color: var(--primary);
      }
    }
  }

  &__item {
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    position: relative;
    color: var(--grey-500);
    border-radius: 10px;
    background-color: transparent;

    i {
      font-weight: 300;
      font-size: 22px;
      margin: 0 13px;
    }

    &--open-btn {
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      margin-right: 13px;
      background-color: var(--primary);
      border: 1px solid var(--primary);
    }

    &-box {
      display: flex;
      align-items: center;
    }

    &--active,
    &:hover {
      color: var(--white);
      text-decoration: none;
      background-color: var(--primary);

      .menu__item-text-container::before {
        color: var(--white);
      }
    }
  }
}
