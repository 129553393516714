.empty-state {
  display: flex;
  flex-flow: column;
  gap: 18px;
  width: 380px;
  margin: 0 auto;

  &__title {
    color: var(--grey-400);
    font-weight: 600;
    font-size: 18px;
  }

  &__content {
    font-size: 14px;
  }
}
