$boxPadding: 30px;

.stats {
  display: grid;
  grid-template-columns: 1fr 420px;
  gap: 34px;

  @media only screen and (max-width: 1300px) {
    display: flex;
    flex-flow: column;
    grid-template-columns: unset;
    gap: 0;
  }
}

.stats-box {
  position: relative;
  margin-top: 34px;

  .chart__box {
    margin-top: 0;
  }

  &--without-margin {
    margin-top: 0;
  }

  &--chart {
    min-height: 452px;

    .empty-state {
      margin-top: 120px;
      position: relative;
    }
  }

  &--pie-chart {
    min-height: 333px;

    .empty-state {
      margin-top: 77px;
      width: 100%;
    }

    .chart__box {
      margin-top: 0;
    }
  }

  &--table .page-box__tabs {
    margin-bottom: 0;
  }

  .page-box {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $boxPadding;
      position: absolute;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      z-index: 2;
    }

    &__row--select {
      .dropdown {
        margin-bottom: 14px;
      }

      .btn {
        width: 120px;
        margin-top: 24px;
      }
    }

    &__input {
      position: relative;
    }
  }

  &__average {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    .place-box {
      margin-left: 7px;
    }
  }
}

.status-tag {
  padding: 0 10px 0 5px;
  font-size: 11px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  border-radius: 5px;
  text-transform: capitalize;
  border: 1px solid var(--grey-200);

  .status {
    margin-right: 7px;
  }

  &--active {
    color: var(--green-100);
    background-color: var(--grey-100);
  }
}

.place-box {
  border-radius: 3px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  font-weight: 800;
  padding: 3px 6px;
}
