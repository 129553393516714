.integration-page {
  &__content {
    display: grid;
    grid-template-columns: 1fr 380px;
    grid-gap: 60px;
  }

  &__integration-box {
    display: flex;
    flex-flow: column;
    gap: 30px;
  }
}

.integration {
  display: flex;
  border: 1px solid var(--grey-200);
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  &--active {
    border-color: var(--primary);
  }

  &__box {
    display: flex;
    gap: 20px;
    align-items: center;
    flex: 1;
  }

  &__image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--gpt::before {
      background-image: url('../../assets/images/openai-icon.svg');
      width: 40px;
      height: 40px;
    }

    &--claude::before {
      background-image: url('../../assets/images/claude-icon.svg');
      width: 100px;
      height: 22px;
    }

    &--gemini::before {
      background-image: url('../../assets/images/gemini-icon.svg');
      width: 80px;
      height: 30px;
    }

    &--sheets::before {
      background-image: url('../../assets/images/sheets-icon.svg');
      width: 45px;
      height: 62px;
    }
  }

  &__content {
    flex: 1 0;
    display: flex;
    flex-flow: column;
    gap: 10px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__desc {
    font-size: 14px;
    line-height: 20px;
  }

  &__action {
    display: inline-flex;
    justify-content: flex-end;
    width: 73px;
    font-size: 12px;
    color: var(--grey-400);
    font-weight: 600;
  }
}
