.page-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px;

  @media only screen and (max-width: 550px) {
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  a {
    color: var(--text-black);

    &:hover {
      color: var(--text-black);
    }
  }
}

.trends {
  display: flex;
  align-items: center;
  line-height: 34px;

  &__icon {
    font-family: 'Material Symbols Outlined';
    margin-right: 3px;
  }

  &--green {
    color: var(--green-100);
  }

  &--grey {
    color: var(--grey-400);
  }

  &--red {
    color: var(--red-100);
  }
}
