.prompts-page {
  .table-wrapper {
    padding: 0 0 30px;

    thead {
      th:last-child,
      th:nth-child(5) {
        div {
          justify-content: center;
        }
      }
    }

    td {
      &:first-child div:not(.no-results__title, .no-results__desc) {
        margin: auto;
      }

      &:nth-child(4) {
        font-weight: 600;
      }

      &:nth-child(5) {
        text-align: center;
      }

      &:last-child {
        margin: auto;
        position: relative;
      }

      button {
        margin: auto;
      }

      .more-btns button {
        margin: unset;

        &:disabled {
          color: var(--grey-300);
          cursor: default;
        }
      }
    }
  }

  .more-btns {
    display: flex;
    flex-flow: row-reverse;
    position: relative;
    justify-content: center;

    button {
      background-color: unset;
      color: var(--text-black);
      padding: 0;
      height: unset;
      border-radius: 0;
      border: 0;
      cursor: pointer;

      &::before {
        display: none;
      }

      &:hover {
        color: var(--primary);
      }
    }

    span {
      cursor: default;
      transition: 0.3s ease;

      &:hover {
        color: var(--primary);
      }
    }

    &:hover {
      span {
        color: var(--primary);
      }

      .action-panel {
        padding: 3px 10px;
        opacity: 1;

        &--1 {
          width: 45px;
        }

        &--2 {
          width: 85px;
        }

        &--3 {
          width: 130px;
        }
      }
    }

    .action-panel {
      padding: 0;
      margin: auto 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      position: absolute;
      width: 0;
      height: 36px;
      right: 40px;
      box-sizing: border-box;
      background-color: var(--white);
      transition: 0.3s ease;
      opacity: 0;

      li {
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &__main {
    margin-top: 90px;
  }
}
