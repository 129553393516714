.layout {
  display: flex;

  &__login-box {
    display: flex;
    align-items: center;
    height: 60px;
  }

  &__belt {
    min-height: 60px;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-200);
    justify-content: flex-end;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1003;
    width: 100%;
    align-items: center;

    .btn {
      margin-right: 25px;
    }

    @media only screen and (max-width: 1169px) {
      justify-content: space-between;
    }

    .logo {
      margin-left: 20px;
      display: none;

      .logo-box {
        width: 120px;
        height: 24px;
      }

      @media only screen and (max-width: 1169px) {
        display: block;
      }
    }
  }

  &__page {
    width: 100%;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  &__left {
    width: 260px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    flex: 1;
    z-index: 1004;

    @media only screen and (max-width: 1169px) {
      display: none;
    }
  }

  &__right {
    display: flex;
    flex-flow: column;
    width: calc(100% - 260px);
    position: relative;

    @media only screen and (max-width: 1169px) {
      width: 100%;
    }
  }
}
