button {
  transition: 0.3s ease;
  cursor: pointer;

  span {
    z-index: 1;
    position: relative;
  }

  &.btn--disabled {
    background-color: var(--grey-100) !important;
    border: 1px solid var(--grey-100) !important;
    color: var(--grey-500) !important;
    cursor: default;
    pointer-events: none;
  }

  &.btn--disabled-tooltip-color {
    background-color: var(--grey-100) !important;
    border: 1px solid var(--grey-100) !important;
    color: var(--grey-500) !important;
    cursor: default;
  }

  &.btn--disabled-tooltip {
    color: var(--grey-500) !important;
    cursor: default;
  }
}

a.btn {
  color: var(--primary-300);
  background-color: var(--white);

  &:hover {
    color: var(--white);
    text-decoration: none;
    background-color: var(--primary-300);
  }
}

.btn {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  transition: 0.3s ease;

  &--dev::before {
    content: 'dev';
    position: absolute;
    font-size: 10px;
    color: var(--white);
    height: 14px;
    background-color: var(--grey-500);
    padding: 2px 5px;
    left: -2px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(-90deg);
  }

  &:not(&.btn--link):not(&button[data-pc-section='pagebutton']) {
    padding: 5px 20px;
    border-radius: 5px;
    color: var(--white);
    font-weight: 600;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    height: 50px;
    min-width: 160px;

    &:hover {
      border-color: var(--primary-300);
      background-color: var(--primary-300);
    }

    &.btn {
      &--secondary {
        border-color: var(--secondary);
        background-color: var(--secondary);
        color: var(--text-black);

        &::before {
          background: var(--secondary-100);
        }

        &:hover {
          border-color: var(--grey-200);
          background-color: var(--grey-200);
        }
      }

      &--gray {
        background-color: transparent;
        border: 1px solid var(--grey-400);
        color: var(--grey-400);

        &::before {
          display: none;
        }

        &:hover {
          border: 1px solid var(--grey-300);
          color: var(--grey-300);
        }
      }

      &--red {
        background-color: var(--red-300);
        border: 1px solid var(--red-300);
        color: var(--white);

        &::before {
          display: none;
        }

        &:hover {
          background-color: var(--white);
          border: 1px solid var(--red-300);
          color: var(--red-300);
        }
      }

      &--blue {
        background-color: var(--red-300);
        border: 1px solid var(--red-300);
        color: var(--white);

        &::before {
          display: none;
        }

        &:hover {
          background-color: var(--white);
          border: 1px solid var(--red-300);
          color: var(--red-300);
        }
      }

      + ul {
        position: absolute;
      }

      &:disabled,
      &--disabled {
        background-color: var(--grey-100);
        border: 1px solid var(--grey-100);
        color: var(--grey-500);
        cursor: default;
      }

      &--sm {
        height: 35px;
      }

      &--xs {
        height: 35px;
        min-width: auto;
      }

      &--toggle {
        padding: 0;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        min-width: unset;
        box-sizing: border-box;
      }
    }
  }

  &--link {
    border: 0;
    display: inline-block;
    font-size: 14px;
    padding: 0;
    height: auto;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    &-red {
      color: var(--red-100);

      &:hover {
        color: var(--red-100);
      }
    }

    &-blue {
      color: var(--primary-200);

      &:hover {
        color: var(--primary-100);
      }
    }
  }
}

.action-btn {
  display: inline-block;
  color: var(--text-black);
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: var(--primary);
  }
}
