.tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;

  @media only screen and (max-width: 850px) {
    display: flex;
    flex-flow: column;
    grid-template-columns: unset;
    gap: 34px;
  }

  .tile-box {
    display: flex;
    flex-flow: column;
    background-color: var(--white);
    padding: 20px;
    gap: 3px;
    border-radius: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    border: 1px solid var(--grey-200);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 45px;
      width: 45px;
      background-color: var(--primary-200);
      opacity: 0.8;
      left: 10px;
      top: 10px;
      border-radius: 50%;
    }

    .material-symbols-outlined:not(.question-mark) {
      z-index: 2;
      position: absolute;
      width: 45px;
      left: 10px;
      top: 10px;
      color: var(--white);
      font-size: 30px;
      text-align: center;
      line-height: 45px;
    }

    &--green {
      background: linear-gradient(90deg, var(--green-100) 50%, var(--green-200) 100%);
    }

    &--blue {
      background: linear-gradient(90deg, var(--blue-100) 50%, var(--blue-200) 100%);
    }

    &--red {
      background: linear-gradient(90deg, var(--red-100) 50%, var(--red-200) 100%);
    }

    &__name {
      margin-left: 60px;
      font-weight: 400;
      font-size: 16px;
      color: var(--text-black);
      display: flex;
      align-items: center;
    }

    &__value {
      margin-left: 60px;
      font-weight: 600;
      font-size: 28px;
      color: var(--text-black);
    }
  }

  &--page {
    .tile-box {
      background: var(--white);

      &__name,
      &__value {
        color: var(--text-black);
        margin-left: 0;
      }

      &::before {
        display: none;
      }

      &--green {
        border-left: var(--green-100) 10px solid;
      }

      &--blue {
        border-left: var(--blue-100) 10px solid;
      }

      &--red {
        border-left: var(--red-100) 10px solid;
      }
    }
  }
}
