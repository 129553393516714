.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  &__overflow {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    opacity: 0.7;
  }

  &__spinner {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: transparent var(--primary);
    animation: spinner 1s infinite;
    position: absolute;
    inset: 0;
    margin: auto;
  }
}

@keyframes spinner {
  to {
    transform: rotate(0.5turn);
  }
}
