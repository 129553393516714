.login-box {
  $rightPadding: 15px;

  display: flex;
  justify-content: space-between;
  gap: 18px;
  align-items: center;
  font-size: 14px;
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  padding: 0 $rightPadding 0 0;
  position: relative;
  cursor: default;

  @media only screen and (max-width: 1169px) {
    width: 40px;
    margin-right: 20px;
  }

  &__content {
    $contentWidth: $rightPadding + 22px;

    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 14px;
    width: calc(100% - #{$contentWidth});
    flex: none;
  }

  &__avatar {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: linear-gradient(to left, var(--primary-100), var(--primary-200), var(--primary-300));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 700;
    flex: none;
  }

  &__name {
    color: var(--text-black);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 22px;

    @media only screen and (max-width: 1169px) {
      display: none;
    }
  }

  &::after {
    font-family: 'Material Symbols Outlined';
    content: '\e313';
    font-size: 22px;
    transition: all 0.3s ease;

    @media only screen and (max-width: 1169px) {
      display: none;
    }
  }

  &__panel {
    height: 0;
    overflow: hidden;
    top: 60px;
    height: 0;
    width: 100%;
    transition: all 0.3s ease;
    background-color: var(--white);
    position: absolute;
    box-shadow: 0 2px 3px 0 var(--grey-200);
    right: 0;
    opacity: 0;
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    gap: 8px;

    @media only screen and (max-width: 1169px) {
      top: 40px;
      width: 220px;
    }

    button {
      background-color: var(--white);
      border: 0;
      padding-left: 0;
      cursor: pointer;
      font-weight: 400;
      border-radius: 0;

      &:hover::before {
        background: var(--primary);
        width: 5px;
        top: unset;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    padding-right: 10px;
    transition: all 0.3s ease;
    overflow: hidden;
    color: var(--text-black);

    &::before {
      content: '';
      width: 5px;
      height: 0;
      background-color: var(--primary);
      position: absolute;
      left: 0;
      transition: all 0.3s ease;
    }

    i {
      margin-left: 15px;
      margin-right: 13px;
    }

    &:hover {
      color: var(--text-black);
      text-decoration: none;

      &::before {
        height: 100%;
      }
    }

    &--active {
      color: var(--text-black);
      text-decoration: none;

      &::before {
        height: 100%;
      }
    }
  }

  &:hover {
    .login-box__panel {
      height: 108px;
      opacity: 1;
    }

    &::after {
      transform: rotate(180deg);
    }
  }
}
