.project-page {
  .table-wrapper {
    padding: 0 0 30px;
  }

  a {
    color: var(--primary);
    font-weight: 600;

    &:hover {
      color: var(--primary-100);
    }
  }

  .table__cell--center .tag {
    cursor: pointer;
  }

  tbody tr {
    position: relative;

    .project-name {
      &__additional {
        position: absolute;
        font-size: 10px;
        color: var(--grey-400);
        font-weight: 600;
        bottom: 2px;
      }

      span {
        margin-left: 3px;
        margin-right: 3px;
        color: var(--red-100);

        &.project-name__ok {
          color: var(--green-100);
        }
      }
    }
  }
}
