.change-name-dialog {
  input {
    margin-bottom: 25px;
  }

  &__input {
    position: relative;

    span {
      bottom: 10px;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  &__row {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 8px;
    font-size: 14px;

    div[data-pc-name='radiobutton'] {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 2px;
    }

    label {
      flex: auto;
    }
  }
}
